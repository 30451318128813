<template>
  <app-form-field v-bind="fieldBinding">
    <template #default="{ inputClass: _inputClass }">
      <textarea
        ref="textarea"
        autocapitalize="off"
        class="resize-none"
        :class="[_inputClass, inputClass]"
        :placeholder="placeholder"
        :readonly="isReadonly"
        :rows="rows"
        :value="value"
        v-on="listeners"
      />
    </template>

    <template #append>
      <slot name="append" />
    </template>
  </app-form-field>
</template>

<script lang="ts" setup>
import {
  appFormInputProperties,
  useAppFormInput,
} from "../composables/form-input.hook";

const properties = defineProps({
  ...appFormInputProperties,
  rows: { type: Number, default: 3 },
  autoGrow: { type: Boolean, default: true },
  inputClass: { type: String, default: undefined },
});

const { value, listeners, fieldBinding, isReadonly } =
  useAppFormInput(properties);

const textareaElement = templateRef<HTMLTextAreaElement>("textarea");

onMounted(async () => {
  if (properties.autoGrow) {
    textareaElement.value.style.minHeight = `${textareaElement.value.clientHeight}px`;

    watch(value, (newValue, oldValue) => {
      // Ensure input is not resize when bluring an empty field on init
      if (!newValue && !oldValue) return;

      textareaElement.value.style.height = 0 + "px";
      textareaElement.value.style.height =
        textareaElement.value.scrollHeight + "px";
    });
  }

  if (process.client && properties.autofocus) {
    /*
    Ensure the forced focus does not break any transition (ie: focused input in a dialog)
     */
    await nextTick();
    textareaElement.value?.focus();
  }
});
</script>
